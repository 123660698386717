import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./image.css";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        imageOne: file(relativePath: { eq: "massage.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageTwo: file(relativePath: { eq: "flower-home.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageThree: file(relativePath: { eq: "hand.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="image-container">
        <div className="image-content">
          <Img fluid={data.imageOne.childImageSharp.fluid} style={{ marginBottom: `10px`}}/>
          <p>
            Nature Health specialises in traditional Chinese medical practices in East Molesey, Surrey offering a wide range of treatments of which include, acupuncture, massage, cupping, moxibustion, reflexology and gua sha. Traditional Chinese medicine is widely used in China where it has a long history, and one of the basic principles of traditional Chinese medicine is Qi (Chi), which is believed to be a vital force forming part of any living entity. At Nature Health our specialists aim to balance and restore your Qi with a consultation first and diagnosis aims to trace symptoms to patterns of underlying disharmony by measuring the pulse, inspecting the tongue, skin and eyes. 
          </p>
          <p>
            Chinese massage therapy we offer:
            <li>Tui Na massage (similar to deep tissue massage) – relieves sore points and blockages in the body’s muscular system</li>
            <li>Lymphatic drainage massage – a form of very light massage that stimulates and encourage lymph flow in the body to deliver nutrients to the cells and carry away excess water, cellular waste, bacteria, viruses and toxins</li>
            <li>Relaxing massage – light pressure, helps eliminate accumulated muscle tension and relieve stress</li>
          </p>
        </div>
        <div className="image-content">
          <Img fluid={data.imageTwo.childImageSharp.fluid} style={{ marginBottom: `10px`}}/>
          <p>
            <b>Acupuncture</b> is one of the best known traditional Chinese medical practice, which involves fine needles inserted at certain acupuncture points on the body for healing purposes. The National Institute for Health and Care Excellence (NICE) recommends considering acupuncture as a treatment for: <b>chronic tension-type headaches, migraines</b> but it can also often be used to treat: <b>chronic pain (such as neck pain), joint pain, nausea, anxiety, insomnia and dental pain</b>.
          </p>
          <p>
            <b>Cupping</b> therapy is an ancient form of alternative medicine, which comes in different forms; the most common are dry, wet and fire cupping. Bamboo, silicone or glass cups are applied onto the skin and a suction is created, pulling the skin up, to help increase blood flow to certain areas of the body. Primarily used for pain relief and muscle relaxation, it can be used to aid in anxiety, depression, skin problems and bronchial congestion. Professional athletes such as Michael Phelps and Andy Murray advocate the technique, believing it to be an essential part of sports recovery by helping relieve stiffness and injury.
          </p>
        </div>
        <div className="image-content">
          <Img fluid={data.imageThree.childImageSharp.fluid} style={{ marginBottom: `10px`}}/>
          <p>
          At Nature Health we specialise in <b>Gua Sha</b>, also known as kerokan or coining. Our doctor who has practicing Gua Sha for over 20 years performs the therapy using a bone or jade tool, scraping across the skin to redirect energy flow which stagnant energy is then broken down, reducing inflammation, increasing blood flow and stimulating the lymphatic system to promote healing in the body. This technique has been used for centuries to treat ailments such as<b> fever, muscle pain, tension, inflammation, chronic cough, sinusitis, migraines and promote weight loss</b>. It helps in dispersing toxins by increased circulation aiding lymphatic and immune systems. 
          Gua Sha is also used as a beauty massage technique which has long term benefits, improving<b> microcirculation, reducing wrinkles, rejuvenate, tone and smooth skin, boost collagen, combat pigmentation, dark circles and puffy eyes </b>and creating more definition in the jawline.
          </p>
          <p>
          During the treatment, you may feel some pain and discomfort from the pressure of the jade or bone tool and skin may bruise but this is part of the healing process of the body. Throughout the treatment, most people feel a sense of weightlessness, greater energy and feeling lighter afterwards. 
          The redness (or sha) from the scraping on your body can vary according to the area, degree and nature of a health problem, which can also be an indicator of what may be going on internally. 
          </p>
          <a className="link" href="/treatments">To see our price list for the above treatments, please click here</a>
        </div>
      </div>
    )}
  />
)
export default Image
